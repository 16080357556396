<template>
  <section class="new" style="background: white;">
    <div id="index-stats">
      <h2>Sondages / Études</h2>

      <div id="carousel-dots">
      <span v-for="i in this.slides" :key="i" @click="this.slide = i - 1; this.stop();" :class="{ active : this.slide === i - 1 }">
        <font-awesome-icon icon="circle"></font-awesome-icon>
      </span>
      </div>

      <div id="stats-carousel">
        <Transition name="fade" mode="out-in">
          <div class="chart" :key="0" v-if="this.slide === 0">
            <h3>
              Les critères de choix d'un box de stockage en France
            </h3>
            <small>
              Sondage effectué par le site Location-gardemeuble.fr sur un panel de 500 clients ayant loué un box entre le 1er janvier 2024 et le 15 mars 2024.
            </small>

            <Bar :chartData="this.charts.criteres.data" :chartOptions="this.charts.criteres.options" style="width: 100%; max-width: min(1000px, 95vw); height: 300px; margin: auto;" />

            <aside>
              <h4>Le choix d'un box de stockage dépend de plusieurs critères importants que voici :</h4>
              <ul>
                <li>
                  <b>Taille du box :</b>
                  Sélectionnez un box suffisamment grand pour contenir tous vos biens, mais pas trop grand pour éviter de payer pour un espace inutilisé. Évaluez soigneusement la quantité et le volume des articles que vous prévoyez de stocker. 15% des sondés ont répondu que ce critère de taille était important dans leur choix.
                </li>
                <li>
                  <b>Emplacement :</b>
                  Choisissez un emplacement pratique et accessible, proche de votre domicile ou de votre lieu de travail, pour faciliter l'accès à vos biens. Considérez également la proximité des principales voies de circulation et des transports en commun. A la troisième place avec plus d’1 client sur 2, soit 58%.
                </li>
                <li>
                  <b>Accessibilité :</b>
                  Optez pour un centre de stockage avec des heures d'accès flexibles qui correspondent à votre emploi du temps. La plupart des centres offrent un accès 24/24 et 7/7, ce qui peut être crucial si vous avez besoin de récupérer vos biens à tout moment. L’accès est pour près de la moitié des clients un critère décisif.
                </li>
                <li>
                  <b>Sécurité :</b>
                  Assurez-vous que le centre de stockage propose des mesures de sécurité adéquates telles que des caméras de surveillance, un système de contrôle d'accès, des clôtures sécurisées, et éventuellement une surveillance humaine. Sur le podium avec la deuxième place, 6 clients sur 10, ce qui le place en critère immuable pour choisir un box.
                </li>
                <li>
                  <b>Condition de stockage Ventilation, isolation :</b>
                  Une bonne ventilation est importante. Un box bien sec est crucial. Pour les biens sensibles à la chaleur, à l'humidité ou aux variations de température, recherchez un centre de stockage avec des installations de ventilation pour maintenir des conditions optimales. Un entrepôt isolé. Environ 4 personnes sur 10 sont sensibles à cela. Tout dépendra du type de biens à stocker.
                </li>
                <li>
                  <b>Propreté et entretien :</b>
                  Visitez le centre de stockage pour évaluer la propreté et l'entretien des installations. Assurez-vous que les box sont propres et bien entretenus pour protéger vos biens de la poussière, de la saleté et des nuisibles. Avec un bon 44% des clients interrogés, ce critère reste un élément de choix dans la prise de décision.
                </li>
                <li>
                  <b>Assurance :</b>
                  Vérifiez si le centre de stockage propose une assurance pour couvrir vos biens en cas de dommages, de vol ou de sinistre. Sinon, vous pouvez envisager de souscrire une assurance supplémentaire pour une tranquillité d'esprit accrue. L’assurance de vos biens est obligatoire. Avec seulement 5%, ce critère n’est pas un élément fondamental dans la prise de décision. On peut cependant être influencé si l’assurance est offerte.
                </li>
                <li>
                  <b>Tarifs et conditions :</b>
                  Comparez les tarifs de location et examinez attentivement les conditions du contrat, y compris la durée minimale de location, les frais supplémentaires, les politiques d'annulation et de résiliation, etc. Tout en haut du podium, à la première place, le prix est le critère qui fera sûrement basculer votre choix.
                </li>
                <li>
                  <b>Convivialité :</b>
                  Etre reçu par un personnel souriant, sympathique. Il faut que les échanges soient fluides, agréables et clairs. Avoir une bonne relation entre le client et le personnel du centre est important pour 39% des personnes à la recherche d’un box. Le service client est encore important aux yeux des locataires. Chaque client est différent, il confie ses meubles et affaires personnelles au garde meuble. Les relations entre les personnes doivent être bonnes.
                </li>
                <li>
                  <b>Avis :</b>
                  On regarde tous les avis et les commentaires sur le web. Parfois à prendre avec des pincettes, mais les avis influencent souvent notre choix, d’où une importance raisonnable à ne pas négliger avec un score de 28%.
                </li>
                <li>
                  <b>Papier, location en ligne :</b>
                  Près de 20% des gens apporte une importance au fait d’avoir des démarches simples dans le processus de location. Pas trop de papier. Une solution dématérialisée est toujours appréciée. La tendance dans les prochaines années devrait s’accentuer car la dématérialisation est bien plus simple.
                </li>
              </ul>
            </aside>
          </div>
          <div class="chart" :key="1" v-else-if="this.slide === 1">
            <h3>
              Les durées de location d’un box en France
            </h3>
            <small>
              Résultats de l’étude du site Location-gardemeuble.fr sur 650 clients ayant loué un box entre le 1er janvier 2022 et le 30 juillet 2023.
            </small>

            <div class="flexible">
              <Doughnut :chartData="this.charts.duree.data" :chartOptions="this.charts.duree.options" style="width: 100%; max-width: min(400px, 95vw); margin: auto;" />
              <aside>
                <p>
                  La durée de location d'un box de stockage en France peut varier considérablement en fonction des besoins individuels et des circonstances spécifiques de chaque personne. Cependant, voici quelques tendances générales observées :
                </p>

                <ul>
                  <li>
                    <b>Location à court terme :</b> Moins de 10% des personnes sondées louent un box de stockage pour une période relativement courte, allant de quelques semaines à 1 mois. Cela peut être nécessaire lors d'un déménagement, d'une rénovation de domicile, d'un voyage prolongé, ou d'un besoin temporaire de stockage.
                  </li>
                  <li>
                    <b>Location à moyen terme :</b> Plus de 70% des locataires de box optent pour une location de box de stockage pour une durée moyenne, qui peut aller de 3 mois à un an. Cette période est souvent choisie pour des transitions telles que des affectations temporaires, des études à l'étranger, ou des projets professionnels nécessitant un stockage à moyen terme.
                  </li>
                  <li>
                    <b>Location à long terme :</b> 12% des individus et entreprises choisissent de louer un box de stockage sur une période prolongée, au-delà de 12 mois. Cela peut être nécessaire pour stocker des biens excédentaires, des archives commerciales, des équipements saisonniers ou des objets de valeur à long terme.
                  </li>
                </ul>

                <p>
                  La durée de location d'un box de stockage dépendra des besoins spécifiques de chaque individu ou entreprise. La location en self-stockage est sans engagement.
                </p>

                <p>
                  Chez Location-gardemeuble.fr, nous notons également dans nos statistiques internes que près de 20% des clients vont louer un box plus de 3 ans. Et que 20% des locataires reviennent au moins une fois louer un box de stockage.
                </p>
              </aside>
            </div>

            <h3 style="margin-top: 1em;">
              Pour quelles raisons louer un box en France ?
            </h3>
            <small>
              Enquête faite sur 400 clients du site Location-gardemeuble.fr, dans les grandes villes françaises comme Paris, Lyon, Marseille, Toulouse, Lille, au premier trimestre 2024.
            </small>
            <Bar :chartData="this.charts.raisons.data" :chartOptions="this.charts.raisons.options" style="width: 100%; max-width: min(1000px, 95vw); height: 400px; margin: auto;" />
            <aside>
              <p>
                Les raisons pour lesquelles les gens louent un box de stockage en self-stockage peuvent être diverses et variées.
                Les deux principales seront le déménagement et le manque de place chez soi ou dans son entreprise.
                Le self-stockage offre une solution pratique et flexible pour stocker une grande variété d'articles de manière sécurisée et accessible.
              </p>
            </aside>
          </div>
          <div class="chart" :key="2" v-else-if="this.slide === 2">
            <h3>
              A quel moment les français se renseignent sur leur location de box ?
            </h3>
            <Bar :chartData="this.charts.quand.data" :chartOptions="this.charts.quand.options" style="width: 100%; max-width: min(1000px, 95vw); height: 300px; margin: auto;" />

            <h3 style="margin-top: 1em;">
              Les heures de réservation
            </h3>
            <aside>
              <p style="text-align: center;">
                Chez Location-gardemeuble.fr, nous notons également que les réservations de box se font à 67% entre 9h00 et 17h00.
                <br>
                Les chiffres sont de 4% entre 21h00 et 7h00 et de 28% avant 9h00 du matin et entre 17h00 et 21h00.
              </p>
            </aside>

            <Bar :chartData="this.charts.heures.data" :chartOptions="this.charts.heures.options" style="width: 100%; max-width: 500px; height: 150px; margin: auto;" />

            <div class="flexible" style="margin-top: 2em;">
              <div>
                <h3>
                  Location de box par surface
                </h3>
                <Bar :chartData="this.charts.surface.data" :chartOptions="this.charts.surface.options" style="width: 100%; max-width: min(350px, 90vw); max-height: 300px; margin: auto;" />
                <aside>
                  <p style="text-align: center;">
                    La taille moyenne d’un box de stockage loué en France est de 5.6m².
                  </p>
                </aside>
              </div>
              <div>
                <h3>
                  Volume des affaires réellement stockées dans un box
                </h3>
                <Doughnut :chartData="this.charts.volume.data" :chartOptions="this.charts.volume.options" style="width: 100%; max-width: min(300px, 95vw); margin: auto;" />
              </div>
            </div>
          </div>
        </Transition>
      </div>
    </div>
  </section>
</template>

<script>
import { Options, Vue } from "vue-class-component";
import { useStore } from "vuex";
import { key } from "@/store";

import { library } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { faCircle } from "@fortawesome/free-solid-svg-icons";
import { defineAsyncComponent } from "vue";

library.add( faCircle );

import { Bar, Doughnut } from "vue-chartjs";
import { Chart as ChartJS, Title, Tooltip, Legend, ArcElement, BarElement, CategoryScale, LinearScale } from "chart.js";

ChartJS.register( Title, Tooltip, ArcElement, Legend, BarElement, CategoryScale, LinearScale );


@Options( {
  components : {
    FontAwesomeIcon,
    Bar, Doughnut
  }
} )
export default class Stats extends Vue {
  store = useStore( key );

  slide = 0;
  slides = 3;
  interval = null;

  stop()
  {
    clearInterval( this.interval );
    this.interval = null;
  }

  mounted()
  {
    this.interval = setInterval( () => {
      this.slide = (this.slide + 1) % this.slides;
    }, 10000 );
  }

  charts = {
    criteres : {
      data    : {
        labels   : ["Tarif", "Sécurité", "Emplacement", "Accessibilité", "Propreté, entretien",
                    "Ventilation, isolation", "Convivialité", "Avis", "Paperasserie, Location en ligne",
                    "Taille du box", "Assurance"],
        datasets : [
          {
            data            : [74, 60, 58, 47, 44, 41, 39, 28, 19, 15, 5],
            backgroundColor : [
              "rgb(49, 54, 140)",
              "rgb(67, 63, 127)",
              "rgb(84, 72, 115)",
              "rgb(102, 82, 102)",
              "rgb(120, 91, 89)",
              "rgb(138, 100, 77)",
              "rgb(155, 109, 64)",
              "rgb(173, 118, 52)",
              "rgb(191, 127, 39)",
              "rgb(209, 137, 26)",
              "rgb(226, 146, 14)",
              "rgb(244, 155, 1)",
            ],
          },
        ]
      },
      options : {
        animations          : false,
        scales              : {
          x : {
            ticks : {
              color           : 'black',
              font            : {
                size : 13
              },
              align           : 'center',
              crossAlign      : 'center',
              autoSkip        : false,
              autoSkipPadding : false,
              labelOffset     : this.store.getters.isMobile ? -8 : 0,
              maxRotation     : this.store.getters.isMobile ? 90 : 0,
              minRotation     : this.store.getters.isMobile ? 90 : 0,
              callback        : function( index ){
                const label = this.getLabelForValue( index );
                if( window.innerWidth < 768 )
                {
                  return label.length > 10 ? label.split( "," )[0] : label;
                }
                else
                {
                  return label.length > 10 ? label.split( "," ) : label;
                }
              }
            },
            grid  : {
              display : false
            }
          },
          y : {
            ticks : {
              color : 'black',
              font  : {
                size : 12
              }
            }

          }
        },
        plugins             : {
          legend : {
            display : false
          }
        },
        responsive          : true,
        maintainAspectRatio : false
      }
    },
    duree    : {
      data    : {
        labels   : ["1 mois", "2-5 mois", "6-12 mois", "Plus de 12 mois"],
        datasets : [
          {
            data            : [8, 43, 32, 17],
            backgroundColor : [
              'rgb(67,113,194)',
              'rgb(235,124,49)',
              'rgb(164,164,164)',
              'rgb(253,190,0)',
            ],
          },
        ]
      },
      options : {
        animations          : false,
        cutout              : '70%',
        plugins             : {
          legend : {
            display : true
          }
        },
        responsive          : true,
        maintainAspectRatio : true,
      }
    },
    raisons  : {
      data    : {
        labels   : ["Déménagement", "Manque d'espace", "Autre", "Travaux de rénovation", "Divorce",
                    "Mutation", "Etudiant", "Absence de cave ou de garage sécurisé", "Archivage obligatoire",
                    "Militaire", "Décès", "Articles saisonniers et de loisirs"],
        datasets : [
          {
            data            : [37, 19, 10, 7, 6, 4, 4, 4, 3, 2, 2, 2],
            backgroundColor : [
              "rgb(49, 54, 140)",
              "rgb(67, 63, 127)",
              "rgb(84, 72, 115)",
              "rgb(102, 82, 102)",
              "rgb(120, 91, 89)",
              "rgb(138, 100, 77)",
              "rgb(155, 109, 64)",
              "rgb(173, 118, 52)",
              "rgb(191, 127, 39)",
              "rgb(209, 137, 26)",
              "rgb(226, 146, 14)",
              "rgb(244, 155, 1)",
            ],
          },
        ]
      },
      options : {
        animations          : false,
        indexAxis           : 'y',
        scales              : {
          y : {
            ticks : {
              color           : 'black',
              font            : {
                size : 13
              },
              autoSkip        : false,
              autoSkipPadding : false,
              callback        : function( index ){
                const label = this.getLabelForValue( index );
                if( window.innerWidth < 768 )
                {
                  return label.length > 10 ? label.substring( 0, 10 ) + "..." : label;
                }
                else
                {
                  return label.length > 10 ? label.split( "," ) : label;
                }
              }
            },
            grid  : {
              display : false
            }
          },
        },
        plugins             : {
          legend : {
            display : false
          }
        },
        responsive          : true,
        maintainAspectRatio : false
      }
    },
    quand    : {
      data    : {
        labels   : ["Janvier", "Février", "Mars", "Avril", "Mai", "Juin", "Juillet", "Août", "Septembre", "Octobre",
                    "Novembre", "Décembre"],
        datasets : [
          {
            data            : [76, 67, 71, 67, 80, 92, 90, 87, 80, 74, 63, 57],
            backgroundColor : [
              "rgb(49, 54, 140)",
              "rgb(67, 63, 127)",
              "rgb(84, 72, 115)",
              "rgb(102, 82, 102)",
              "rgb(120, 91, 89)",
              "rgb(138, 100, 77)",
              "rgb(155, 109, 64)",
              "rgb(173, 118, 52)",
              "rgb(191, 127, 39)",
              "rgb(209, 137, 26)",
              "rgb(226, 146, 14)",
              "rgb(244, 155, 1)",
            ],
          },
        ]
      },
      options : {
        animations          : false,
        indexAxis           : 'x',
        scales              : {
          x : {
            ticks : {
              color           : 'black',
              font            : {
                size : 13
              },
              autoSkip        : false,
              autoSkipPadding : false,
              callback        : function( index ){
                const label = this.getLabelForValue( index );
                if( window.innerWidth < 768 )
                {
                  return label.length > 10 ? label.substring( 0, 10 ) + "..." : label;
                }
                else
                {
                  return label.length > 10 ? label.split( "," ) : label;
                }
              }
            },
            grid  : {
              display : false
            }
          },
        },
        plugins             : {
          legend : {
            display : false
          }
        },
        responsive          : true,
        maintainAspectRatio : false
      }
    },
    heures   : {
      data    : {
        labels   : ["21h-7h", "7-9h et 17-21h", "9h-17h"],
        datasets : [
          {
            data            : [4, 28, 68],
            backgroundColor : [
              'rgb(67,113,194)',
              'rgb(235,124,49)',
              'rgb(253,190,0)',
            ],
          },
        ]
      },
      options : {
        animations          : false,
        indexAxis           : 'y',
        scales              : {
          y : {
            ticks : {
              color           : 'black',
              font            : {
                size : 13
              },
              autoSkip        : false,
              autoSkipPadding : false,
              callback        : function( index ){
                const label = this.getLabelForValue( index );
                if( window.innerWidth < 768 )
                {
                  return label.length > 10 ? label.substring( 0, 10 ) + "..." : label;
                }
                else
                {
                  return label.length > 10 ? label.split( "," ) : label;
                }
              }
            },
            grid  : {
              display : false
            }
          },
        },
        plugins             : {
          legend : {
            display : false
          }
        },
        responsive          : true,
        maintainAspectRatio : false
      }
    },
    surface    : {
      data    : {
        labels   : ["Entre 1 et 5m²", "Entre 6 et 10m²", "Plus de 10m²"],
        datasets : [
          {
            data            : [63,25,12],
            backgroundColor : [
              'rgb(67,113,194)',
              'rgb(235,124,49)',
              'rgb(253,190,0)',
            ],
          },
        ]
      },
      options : {
        animations          : false,
        cutout              : '70%',
        plugins             : {
          legend : {
            display : false
          }
        },
        responsive          : true,
        maintainAspectRatio : false,
      }
    },
    volume    : {
      data    : {
        labels   : ["Entre 1 et 10m³", "Entre 10 et 17m³", "Plus de 18m³"],
        datasets : [
          {
            data            : [63,25,12],
            backgroundColor : [
              'rgb(67,113,194)',
              'rgb(235,124,49)',
              'rgb(253,190,0)',
            ],
          },
        ]
      },
      options : {
        animations          : false,
        cutout              : '0%',
        plugins             : {
          legend : {
            display : true
          }
        },
        responsive          : true,
        maintainAspectRatio : true,
      }
    },
  }

}
</script>

<style lang="scss">
#index-stats
{
  text-align : center;
  padding    : 2em 0.5em 3em;

  max-width  : 1100px;
  margin     : auto;

  @media only all and (min-width : 1023px)
  {

  }
}

#stats-carousel
{
  display    : block;
  margin-top : 1em;

  h3
  {
    font-size     : 1.1em;
    margin-bottom : 0.8em;
  }

  .chart
  {
    small
    {
      display    : block;
      margin     : 1em 0;
      font-size  : 0.7em;
      color      : #696969;
      font-style : italic;
    }

    aside
    {
      font-size   : 0.8em;
      text-align  : justify;
      line-height : 150%;

      li
      {
        margin-bottom : 0.5em;
      }
    }

    .flexible
    {
      display         : flex;
      justify-content : center;
      align-items     : flex-start;

      aside
      {
        max-width : 700px;
        margin    : 0 1em;
        flex      : 2;
      }

      div
      {
        margin: auto;
      }

      @media only all and (max-width : 1023px)
      {
        flex-direction : column;

        aside
        {
          max-width : initial;
          margin    : 0 1em;
          flex      : 1;
        }
      }
    }

  }
}

#carousel-dots
{
  display         : flex;
  justify-content : center;
  align-items     : center;
  margin-top      : 1em;

  span
  {
    transition : all linear 300ms;
    cursor     : pointer;
    font-size  : 1em;
    margin     : 0 0.5em;
    color      : rgba(47, 60, 74, 0.5);

    &.active
    {
      color : #353889;
    }
  }
}
</style>